export default class MxConfig {
    static instance = MxConfig.instance || new MxConfig()

    password = "";
    passwordEntered = false;
    setPassword(pass) {
        this.password = pass;
    }
}

